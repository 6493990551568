import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import MetaInfo from 'vue-meta-info'
import animated from "animate.css" 
import './assets/css/all.css'
import './assets/css/all_flex.css'
// import WOW from 'wowjs/dist/wow'

// Vue.use(WOW)
Vue.use(animated),
Vue.use(MetaInfo),
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
  mounted () {
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')
