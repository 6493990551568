import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/home.vue'

Vue.use(VueRouter)

const routes = [
	{
	  path: '/',
	  name: 'home',
	  component: () => import(/* webpackChunkName: "about" */ '../views/home.vue')
	},
  {
    path: '/12V-HPV',
    name: '12V-HPV',
    component: () => import(/* webpackChunkName: "about" */ '../views/12V-HPV.vue')
  },{
    path: '/24V-HPV',
    name: '24V-HPV',
    component: () => import(/* webpackChunkName: "about" */ '../views/24V-HPV.vue')
  },{
    path: '/48V-HPV',
    name: '48V-HPV',
    component: () => import(/* webpackChunkName: "about" */ '../views/48V-HPV.vue')
  },{
    path: '/12V-APV',
    name: '12V-APV',
    component: () => import(/* webpackChunkName: "about" */ '../views/12V-APV.vue')
  },{
    path: '/24V-APV',
    name: '24V-APV',
    component: () => import(/* webpackChunkName: "about" */ '../views/24V-APV.vue')
  },{
    path: '/48V-APV',
    name: '48V-APV',
    component: () => import(/* webpackChunkName: "about" */ '../views/48V-APV.vue')
  },{
    path: '/12V-HP',
    name: '12V-HP',
    component: () => import(/* webpackChunkName: "about" */ '../views/12V-HP.vue')
  },{
    path: '/24V-HP',
    name: '24V-HP',
    component: () => import(/* webpackChunkName: "about" */ '../views/24V-HP.vue')
  },{
    path: '/48V-HP',
    name: '48V-HP',
    component: () => import(/* webpackChunkName: "about" */ '../views/48V-HP.vue')
  },{
    path: '/96V-HP',
    name: '96V-HP',
    component: () => import(/* webpackChunkName: "about" */ '../views/96V-HP.vue')
  },{
    path: '/12V-APCMini',
    name: '12V-APCMini',
    component: () => import(/* webpackChunkName: "about" */ '../views/12V-APCMini.vue')
  },{
    path: '/24V-APCMini',
    name: '24V-APCMini',
    component: () => import(/* webpackChunkName: "about" */ '../views/24V-APCMini.vue')
  },{
    path: '/12V-APP',
    name: '12V-APP',
    component: () => import(/* webpackChunkName: "about" */ '../views/12V-APP.vue')
  },{
    path: '/24V-APP',
    name: '24V-APP',
    component: () => import(/* webpackChunkName: "about" */ '../views/24V-APP.vue')
  },{
    path: '/48V-APP',
    name: '48V-APP',
    component: () => import(/* webpackChunkName: "about" */ '../views/48V-APP.vue')
  },{
    path: '/12V-AP',
    name: '12V-AP',
    component: () => import(/* webpackChunkName: "about" */ '../views/12V-AP.vue')
  },{
    path: '/24V-AP',
    name: '24V-AP',
    component: () => import(/* webpackChunkName: "about" */ '../views/24V-AP.vue')
  },{
    path: '/48V-AP',
    name: '48V-AP',
    component: () => import(/* webpackChunkName: "about" */ '../views/48V-AP.vue')
  },{
    path: '/12V-APS',
    name: '12V-APS',
    component: () => import(/* webpackChunkName: "about" */ '../views/12V-APS.vue')
  },{
    path: '/24V-APS',
    name: '24V-APS',
    component: () => import(/* webpackChunkName: "about" */ '../views/24V-APS.vue')
  },{
    path: '/48V-APS',
    name: '48V-APS',
    component: () => import(/* webpackChunkName: "about" */ '../views/48V-APS.vue')
  },{
    path: '/12V-TPP',
    name: '12V-TPP',
    component: () => import(/* webpackChunkName: "about" */ '../views/12V-TPP.vue')
  },{
    path: '/24V-TPP',
    name: '24V-TPP',
    component: () => import(/* webpackChunkName: "about" */ '../views/24V-TPP.vue')
  },{
    path: '/48V-TPP',
    name: '48V-TPP',
    component: () => import(/* webpackChunkName: "about" */ '../views/48V-TPP.vue')
  },{
    path: '/48V-TPPV',
    name: '48V-TPPV',
    component: () => import(/* webpackChunkName: "about" */ '../views/48V-TPPV.vue')
  },{
    path: '/12V-PSW7',
    name: '12V-PSW7',
    component: () => import(/* webpackChunkName: "about" */ '../views/12V-PSW7.vue')
  },{
    path: '/24V-PSW7',
    name: '24V-PSW7',
    component: () => import(/* webpackChunkName: "about" */ '../views/24V-PSW7.vue')
  },{
    path: '/48V-PSW7',
    name: '48V-PSW7',
    component: () => import(/* webpackChunkName: "about" */ '../views/48V-PSW7.vue')
  },{
    path: '/12V-OPS',
    name: '12V-OPS',
    component: () => import(/* webpackChunkName: "about" */ '../views/12V-OPS.vue')
  },{
    path: '/24V-OPS',
    name: '24V-OPS',
    component: () => import(/* webpackChunkName: "about" */ '../views/24V-OPS.vue')
  },{
    path: '/12V-SMP',
    name: '12V-SMP',
    component: () => import(/* webpackChunkName: "about" */ '../views/12V-SMP.vue')
  },{
    path: '/24V-SMP',
    name: '24V-SMP',
    component: () => import(/* webpackChunkName: "about" */ '../views/24V-SMP.vue')
  },{
    path: '/48V-SMP',
    name: '48V-SMP',
    component: () => import(/* webpackChunkName: "about" */ '../views/48V-SMP.vue')
  },{
    path: '/40A-MPPT',
    name: '40A-MPPT',
    component: () => import(/* webpackChunkName: "about" */ '../views/40A-MPPT.vue')
  },{
    path: '/60A-MPPT',
    name: '60A-MPPT',
    component: () => import(/* webpackChunkName: "about" */ '../views/60A-MPPT.vue')
  },{
    path: '/CSB',
    name: 'CSB',
    component: () => import(/* webpackChunkName: "about" */ '../views/CSB.vue')
  },{
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "about" */ '../views/contact.vue')
  },
  {
    path: '/InverterCharger/:id?',
    name: 'InverterCharger',
    component: () => import(/* webpackChunkName: "about" */ '../views/InverterCharger.vue')								//产品中心页
  },
  {
    path: '/SolarInverter/:id?',
    name: 'SolarInverter',
    component: () => import(/* webpackChunkName: "about" */ '../views/SolarInverter.vue')								//产品中心页
  },
  {
    path: '/HybirdInverter/:id?',
    name: 'HybirdInverter',
    component: () => import(/* webpackChunkName: "about" */ '../views/HybirdInverter.vue')								//产品中心页
  },
  {
    path: '/PowerConversionSystem/:id?',
    name: 'PowerConversionSystem',
    component: () => import(/* webpackChunkName: "about" */ '../views/PowerConversionSystem.vue')						//产品中心页
  },
  {
    path: '/Others/:id?',
    name: 'Others',
    component: () => import(/* webpackChunkName: "about" */ '../views/Others.vue')										//产品中心页
  },
  {
    path: '/giveServiceTo',
    name: 'giveServiceTo',
    component: () => import(/* webpackChunkName: "about" */ '../views/giveServiceTo.vue')								//服务页
  },
  // 新增
  {
    path: '/48V-UP',
    name: '48V-UP',
    component: () => import(/* webpackChunkName: "about" */ '../views/48V-UP.vue')
  },
  {
    path: '/48V-UPV',
    name: '48V-UPV',
    component: () => import(/* webpackChunkName: "about" */ '../views/48V-UPV.vue')
  },
  {
    path: '/1000V-UP-M',
    name: '1000V-UP-M',
    component: () => import(/* webpackChunkName: "about" */ '../views/1000V-UP-M.vue')
  },
  {
    path: '/1000V-UP-S',
    name: '1000V-UP-S',
    component: () => import(/* webpackChunkName: "about" */ '../views/1000V-UP-S.vue')
  },
  {
    path: '/1000V-UPV-S',
    name: '1000V-UPV-S',
    component: () => import(/* webpackChunkName: "about" */ '../views/1000V-UPV-S.vue')
  },
  {
    path: '/APP-WIFI',
    name: 'APP-WIFI',
    component: () => import(/* webpackChunkName: "about" */ '../views/APP-WIFI.vue')
  },
  {
    path: '/CAN-USB',
    name: 'CAN-USB',
    component: () => import(/* webpackChunkName: "about" */ '../views/CAN-USB.vue')
  },
  {
    path: '/LCD',
    name: 'LCD',
    component: () => import(/* webpackChunkName: "about" */ '../views/LCD.vue')
  },
  {
    path: '/LED',
    name: 'LED',
    component: () => import(/* webpackChunkName: "about" */ '../views/LED.vue')
  },
  {
    path: '/SNMP',
    name: 'SNMP',
    component: () => import(/* webpackChunkName: "about" */ '../views/SNMP.vue')
  },
  {
    path: '/screen-page',
    name: 'screen-page',
    component: () => import(/* webpackChunkName: "about" */ '../views/screen-page.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
